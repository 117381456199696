var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register" },
    [
      _c("h2", [_vm._v("账号注册")]),
      _c(
        "el-form",
        { attrs: { autoComplete: "on", rules: "#", "label-position": "left" } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  id: "username",
                  placeholder: "请输入账号",
                  required: "",
                },
                model: {
                  value: _vm.temp.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "account", $$v)
                  },
                  expression: "temp.account",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  id: "name",
                  placeholder: "请输入名称",
                  required: "",
                },
                model: {
                  value: _vm.temp.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "name", $$v)
                  },
                  expression: "temp.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "email" } },
            [
              _c("el-input", {
                attrs: {
                  type: "email",
                  id: "email",
                  placeholder: "请输入邮箱",
                  required: "",
                },
                model: {
                  value: _vm.temp.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "email", $$v)
                  },
                  expression: "temp.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "请输入密码",
                  id: "password",
                  required: "",
                },
                model: {
                  value: _vm.temp.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "password", $$v)
                  },
                  expression: "temp.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "code" } },
            [
              _c("el-input", {
                attrs: {
                  name: "code",
                  autoComplete: "off",
                  placeholder: "请输入验证码",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.register.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c("identify", {
                staticClass: "imgCode",
                model: {
                  value: _vm.identifyCode,
                  callback: function ($$v) {
                    _vm.identifyCode = $$v
                  },
                  expression: "identifyCode",
                },
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "submit" },
              on: { click: _vm.register },
            },
            [_vm._v(" 注 册 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "register-note" }, [
        _c(
          "p",
          [
            _vm._v("已经有账号？"),
            _c("router-link", { attrs: { to: "/login" } }, [_vm._v("登录")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }