<template>
  <div class="register">
    <h2>账号注册</h2>

    <el-form autoComplete="on" rules="#" label-position="left">
      <el-form-item prop="username">
        <el-input
          type="text"
          id="username"
          placeholder="请输入账号"
          v-model="temp.account"
          required
        />
      </el-form-item>
      <el-form-item prop="name">
        <el-input
          type="text"
          id="name"
          placeholder="请输入名称"
          v-model="temp.name"
          required
        />
      </el-form-item>

      <el-form-item prop="email">
        <el-input
          type="email"
          id="email"
          placeholder="请输入邮箱"
          v-model="temp.email"
          required
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          placeholder="请输入密码"
          id="password"
          v-model="temp.password"
          required
        />
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          name="code"
          @keyup.enter.native="register"
          v-model="code"
          autoComplete="off"
          placeholder="请输入验证码"
        >
        </el-input>
        <identify v-model="identifyCode" class="imgCode"></identify>
      </el-form-item>

      <button @click="register" class="btn btn-primary" type="submit">
        注 册
      </button>
    </el-form>
    <div class="register-note">
      <p>已经有账号？<router-link to="/login">登录</router-link></p>
    </div>
  </div>
</template>

<script>
import identify from "@/components/ImgVerify";
import * as users from "@/api/users";
export default {
  components: {
    identify,
  },
  data() {
    return {
      identifyCode: "",
      code: "",
      temp: {
        id: undefined,
        description: "",
        organizations: "米之亚",
        organizationIds: "4ce405dc-121c-4d97-ab20-6445add59465",
        account: "",
        name: "",
        password: "",
        email: "",
        status: 0,
      },
    };
  },
  methods: {
    // 修改验证码
    changeCode(val) {
      this.identifyCode = val;
    },
    register() {
      if (this.account || this.email || this.password || this.code) {
        return;
      }
      if (this.code != this.identifyCode) {
        this.$message({
          message: "验证码错误，请重新输入",
          type: "error",
        });
        return;
      }

      users.RegisterResult(this.temp).then((response) => {
        this.temp.id = response.result;
        this.$notify({
          title: "成功",
          message: "注册成功",
          type: "success",
          duration: 2000,
        });
      });

      // 在这里添加处理注册逻辑的代码
      console.log(
        `用户名：${this.username}，邮箱：${this.email}，密码：${this.password}`
      );
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;
$color_balck: #333;

/* reset element-ui css */
.register {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $color_balck;
      height: 47px;

      &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  .el-form-item {
    margin-bottom: 35px;
    border-radius: 5px;
    color: #454545;

    .el-form-item__content {
      background: #fff;
      border: 1px solid rgba(223, 223, 223, 1);
    }
    .imgCode {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    &:last-child {
      padding-top: 20px;

      .el-form-item__content {
        border: none;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.register {
  max-width: 600px;
  margin: auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  display: inline-block;
  background-color: #4caf50;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: #3e8e41;
}

.register-note {
  margin-top: 1rem;
  text-align: center;
}

.register-note p {
  margin: 0;
  font-size: 0.9rem;
}

.register-note a {
  color: #4caf50;
  text-decoration: none;
}
.imgCode {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>